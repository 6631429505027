import { graphql } from 'gatsby'
import React from 'react'
import { Router } from '@reach/router'
import { Helmet } from 'react-helmet'
import { Preview } from '@jelly/components'
import { APOLLO_CLIENT_INSTANCE } from 'common/apollo'

const client = APOLLO_CLIENT_INSTANCE

const clients = {
	CDS: APOLLO_CLIENT_INSTANCE,
}

const TEMPLATE_QUERY = require('queries/templates/preview.graphql')

const pageContextFactory = require('common/pageContexts')
const importFunction = (component) => import(`../../${component}`)

const PreviewRoute = ({ queries, slug, uri }) => {
	slug = slug ? slug : uri.replace(/^\/preview\/(.*)?\//, '$1')
	return (
		<Preview
			slug={slug}
			queries={queries}
			TEMPLATE_QUERY={TEMPLATE_QUERY}
			pageContextFactory={pageContextFactory}
			importFunction={importFunction}
			client={client}
			clients={clients}
		/>
	)
}

const App = ({ data: { queries } }) => (
	<>
		<Helmet>
			<meta name="robots" content="noindex, nofollow" />
		</Helmet>
		<Router basepath="/preview">
			<PreviewRoute path="/articles/:slug" queries={queries} />
			<PreviewRoute path="/categories/:slug" queries={queries} />
			<PreviewRoute path="/reviews/:slug" queries={queries} />
			<PreviewRoute default queries={queries} />
		</Router>
	</>
)

export default App

export const GatsbyQuery = graphql`
	query preview {
		queries
	}
`
