const { resolve } = require('path')
const { MAX_TEMPLATE_LIMIT = 10 } = process.env

//need to be called in this way for browser
const GATSBY_SITE_HOST = process.env.GATSBY_SITE_HOST

const dayjs = require('dayjs')
const fetch = require('isomorphic-fetch')

//TODO: make @jelly/utils usable from non esm
function isBrowser() {
	return typeof window !== 'undefined'
}

/**
 * Promise chain helper function to check status
 * Assumes interacting with JSON response
 */
const handleResponse = (response) =>
	response.json().then((json) => {
		if (response.ok && response.status >= 200 && response.status < 300) {
			return json
		} else {
			return Promise.reject({
				...json,
				status: response.status,
				statusText: response.statusText,
			})
		}
	})

// This is an agnostic function that's used to send AJAX requests to either
// external APIs or the Symfony tier that runs BCS
const loadData = (url) => {
	const init = {
		method: 'GET',
		//headers: {
		//'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1',
		//'X-Requested-With': 'XMLHttpRequest',
		//},
	}

	return fetch(url, init)
		.then(handleResponse)
		.catch((error) => {
			console.log('url', url)
			console.error(`Portals loadData module -- ${error.status} error: ${error.statusText}`)
		})
}

exports.articles = ({ path, href, ...article }, template) => {
	const component = article.template === 'link_builder' ? 'linkBuilding' : 'index'
	const skipTOC = article.template === 'long_form'

	return {
		path,
		component: resolve(`src/templates/${template}/${component}.jsx`),
		context: {
			...article,
			docType: template,
			slug: href,
			canonical: `${GATSBY_SITE_HOST}${path}`,
			skipTOC,
		},
	}
}

exports.categories = ({ path, href, ...category }, template) => {
	const component = category.template ? `${template}/${category.template}` : template

	return {
		path,
		component: resolve(`src/templates/${component}/index.jsx`),
		context: {
			...category,
			docType: template,
			slug: href,
			canonical: `${GATSBY_SITE_HOST}${path}`,
		},
	}
}

//TODO Fix should happend on swiftype
const fixContent = (content) => {
	return content.map((item) => {
		if (item.doc_type !== 'adunit' && item.thumbnail && typeof item.thumbnail === 'string') {
			item.thumbnail = { path: item.thumbnail }
		}
		return item
	})
}

exports.hubs = async ({ path, ...hub }) => {
	const hubs = []
	const { href, moduleRiverOfLinks } = hub
	const tab = 'article|category|question'
	const searchTerms = moduleRiverOfLinks.searchTerms ? moduleRiverOfLinks.searchTerms : null
	const component = resolve(`src/templates/hubs/index.jsx`)

	const url = (page) =>
		searchTerms
			? `/api/search/?s=1&r=index&q=${encodeURI(searchTerms)}&t=${tab}&p=${page}`
			: `/api/search/?s=1&r=index&c=${encodeURI(href)}&t=${tab}&p=${page}`

	const firstPage = await loadData(`${GATSBY_SITE_HOST}${url(1)}`).then(({ search, mainContent }) => {
		mainContent.content = fixContent(mainContent.content)

		return { path, component, context: { ...hub, search, mainContent, canonical: `${GATSBY_SITE_HOST}${path}` } }
	})

	const {
		context: { search },
	} = firstPage

	if (isBrowser()) {
		return firstPage
	} else {
		hubs.push(firstPage)
		for (let page = 2; page <= search.num_pages && page <= MAX_TEMPLATE_LIMIT; page++) {
			hubs.push(
				await loadData(`${GATSBY_SITE_HOST}${url(page)}`).then(({ search, mainContent } = {}) => {
					mainContent.content = fixContent(mainContent.content)

					return {
						path: `${path}${page}/`,
						component,
						context: { ...hub, search, mainContent, page, canonical: `${GATSBY_SITE_HOST}${path}` },
					}
				})
			)
		}
		return hubs
	}
}

exports.reviews = ({ path, href, ...review }, template) => {
	const page = {
		path,
		component: resolve(`src/templates/${template}/index.jsx`),
		context: {
			...review,
			docType: template,
			slug: href,
			canonical: `${GATSBY_SITE_HOST}${path}`,
		},
	}

	return page
}

exports.sitemap = ({ _id }) => {
	const [year, month] = _id.split('-')
	const startDate = dayjs(`${year}-${month}-01`, 'YYYY-MM-DD')
	const endDate = startDate.add(1, 'month')
	return {
		path: `/sitemap/${year}/${month}/`,
		component: resolve(`src/templates/sitemap/index.jsx`),
		context: {
			startDate: startDate.format('YYYY/MM'),
			endDate: endDate.format('YYYY/MM'),
		},
	}
}
